<template>
    <el-dialog
        class="editDialog"
        :append-to-body="true"
        width="600px"
        :visible.sync="showEditDialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        @close="$emit('update:isShow', false)"
        :show-close="true"

    >

        <div slot="title" class="title">{{ options.id ? '编辑' : '新增' }}角色</div>

        <div class="content" v-loading="loading">
            <el-form label-width="8em" :model="form" :rules="rules" ref="form">

                <el-form-item label="角色名称" prop="name">
                    <el-input v-model="form.name" placeholder="请输入角色名称" @keyup.enter.native="submit"></el-input>
                </el-form-item>

                <el-form-item label="是否系统管理员" prop="isSystem">
                    <el-radio-group v-model="form.isSystem" size="medium">
                        <el-radio label="Y">是</el-radio>
                        <el-radio label="N">否</el-radio>
                    </el-radio-group>
                </el-form-item>

<!--                <el-form-item label="角色描述" prop="description" style="width:100%;">-->
<!--                    <el-input type="textarea" placeholder="请输入角色描述" v-model="form.description" @keyup.enter.native="submit"></el-input>-->
<!--                </el-form-item>-->

            </el-form>
        </div>

        <div slot="footer" v-show="!loading">
            <el-button type="primary" :loading="submitLoading" @click="submit">保存</el-button>
            <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
        </div>

    </el-dialog>

</template>
<script>

import mixinsForm from '@/mixins/form'
export default {
    components: {},
    mixins: [mixinsForm],
    data() {
        return {
            api:{
                save:this.$api['admin/role'].save,
            },
            rules: {
                name: [{ required: true, message: '请输入角色名称', trigger:['blur','change'] }],
            },
        };
    },
    methods: {
    },
};
</script>
